const API_HOST = process.env.GATSBY_API_HOST;

const API_PATH = "api/1";

const apiRequest = async (method, url, body) => {
  let fetchFn = null;
  if (typeof window !== "undefined" && window.fetch) {
    fetchFn = window.fetch;
  } else {
    fetchFn = require("node-fetch");
  }
  let bodyString = null
  if(body)
  {
    bodyString = JSON.stringify(body)
  }
  if (API_HOST) {
    return await (
      await fetchFn(`${API_HOST}/${url}`, {
        method: method,
        credentials: "include",
        headers: {"Content-Type": "application/json"},
        body: bodyString
      })
    ).json();
  } else {
    console.log("Error: API HOST is not specified");
    throw new Error("API HOST IS NOT SPECIFIED");
  }
};

const getAllResource = async (resource) => {
  return await apiRequest("GET", `${API_PATH}/${resource}`);
};

const getResource = async (resource, id) => {
  return await apiRequest("GET", `${API_PATH}/${resource}/${id}`);
};

const createResource = async (resource, body) => {
  return await apiRequest("POST", `${API_PATH}/${resource}`, body);
};

const updateResource = async (resource, id, body) => {
  return await apiRequest("PUT", `${API_PATH}/${resource}/${id}`, body);
};

const deleteResource = async (resource, id) => {
  return await apiRequest("DELETE", `${API_PATH}/${resource}/${id}`);
};

const resourceCustomFunction = async (method, resource, func, body) => {
  return await apiRequest(method, `${API_PATH}/${resource}/${func}`, body);
};

const imageUploadUrl = `${API_HOST}/${API_PATH}/upload`

module.exports = {
  apiRequest,
  getAllResource,
  getResource,
  createResource,
  updateResource,
  deleteResource,
  resourceCustomFunction,
  imageUploadUrl
};
